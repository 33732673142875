import { Container } from "react-bootstrap";

function TermsCond() {
  return (
    <>
      <Container>
        <h1 style={{ paddingTop: "50px" }}>TERMS AND CONDITIONS</h1>
        <p style={{ paddingTop: "20px" }}>
          PLEASE READ THE FOLLOWING TERMS AND CONDITIONS BEFORE PURCHASING ANY
          TICKETS FOR THIS EVENT FROM EVENTBRITE. BY PURCHASING A TICKET FOR
          THIS EVENT, YOU CONFIRM THAT YOU HAVE READ, UNDERSTOOD AND ACCEPT THE
          FOLLOWING TERMS AND CONDITIONS, WAIVER AND RULES AND REGULATIONS
          ASSOCIATED WITH THIS EVENT.
        </p>
        <hr></hr>
        <h3>COMPLIANCE WITH LOCAL LAW</h3>
        <p>
          You and your support crew must comply with all applicable laws and
          regulations of the United Kingdom, including without limitation, all
          speed regulations, laws of the road, laws relating to the safety of
          vehicles and laws relating to the possession or use of alcohol, drugs
          or other prohibited substances.
        </p>
        <hr></hr>
        <h3>IMAGE RIGHTS </h3>
        <p>
          By participating in the Event you consent to be photographed and,
          should there be any, to be included in the filming of footage
          documenting the Event. You hereby waive any and all rights of
          publicity or privacy and grant the Organiser full and complete
          permission to utilise and exploit your appearance in the Event in any
          and all manner, and media, throughout the world in perpetuity. You
          further agree that the Organiser has the sole discretion to use or
          refuse to use any photographs or footage in which you appear, and that
          if the Organiser does use such photographs or footage, it may be
          edited at the Organiser’s sole discretion. You consent to the use of
          your name, image, likeness, voice and biographical material about you
          in connection with any and all footage, publicity and related
          promotional material and for any and all publicity and promotional
          purposes. You expressly release the Organiser and The British Canal &
          River Trust, their directors, officers, agents, employees, consultants
          and licensees from and against any and all claims which you have or
          may have for invasion of privacy, defamation or any other cause of
          action arising out of the production, distribution, broadcast or
          exhibition of photographs, footage or any promotional materials.
        </p>
        <hr></hr>
        <h3>PERSONAL FILM</h3>
        <p>
          You are only entitled to take photographs and film footage of your
          participation in the Event for non-commercial purposes (that is for
          personal and private use only). Specifically, you are not entitled to
          reproduce or use photographs or footage of the Event on any website,
          in any printed media or matter or in any television programme without
          first obtaining the prior consent of the Organiser. You hereby agree
          to assign (by way of present assignment of future copyright) all your
          right, title and interest in the world-wide copyright and all
          intellectual property rights in such photographs or footage in
          perpetuity, together with exclusive world-wide rights of exploitation
          thereof, to the Organiser.
        </p>
        <hr></hr>
        <h3>HAZARD</h3>
        <p>
          You understand that ultra-distance trail racing is a potentially
          hazardous sport.
          <br></br>
          <br></br>
          You acknowledge the need to provide your own insurance cover for your
          sporting endeavours if required. By signature of the declaration, you
          are accepting that the Organiser has made this clear to you in advance
          of this event. You acknowledge and realise that participation in an
          event such as the Cheshire 100 Ultra Run (the “Event”) is potentially
          dangerous and that there are numerous risks associated with your
          participation. Such risks and dangers include, but are not limited to,
          traffic accidents, collisions with pedestrians, vehicles, other
          drivers, and/or fixed or moving objects, and the negligence of other
          road users. Further, you fully understand and realise that your
          participation in the Event may result in serious bodily injury and/or
          death to yourself or others. You further realise and understand that
          the route of the Event will require running on public roadways upon
          which the hazards of travelling are to be expected. Further, you
          realise that participation in the Event will require strenuous
          physical exertion as participants are required to be active for very
          long periods of time over two days, including the risk of being active
          during darkness, inclement weather and in the close proximity of deep
          water.
        </p>
        <hr></hr>
        <h3>RULES, REGULATIONS & ADVICE FOR RUNNERS AND CREWS</h3>
        <p>
          For reasons of fairness, safety and responsibility you are expected to
          abide by the following rules. Ignorance is not an excuse and failure
          to comply may lead to disqualification.
        </p>
        <p>RACE NUMBERS</p>
        <p>
          You must wear the supplied race number on your front at all times and
          over all clothing at all times during the race. You may not deface or
          alter your race number in any way whatsoever, before or during the
          race.
        </p>
        <p>Always follow the Highway and Countryside Codes.</p>
        <p>
          Show respect for the environment, landowners, local communities and
          other recreational users (e.g. anglers). Be courteous and patient with
          members of the public. The fact that you are taking part in an event
          does not give you priority. Pass walkers, cyclists and anglers
          courteously; make sure they know you are there.
        </p>
        <p>
          Do not climb over walls, hedges or fences, use stiles or gateways
          instead. Re-close closed gates after you and leave open gates open.
        </p>
        <p>
          Ensure you are wearing or carrying the appropriate kit. Weather
          conditions can vary widely over two days and nights. Bring adequate
          clothing and illumination for night time. You could be running on
          public roads in darkness.
        </p>
        <p>
          Stop and assist any competitors in need of medical attention and alert
          Race HQ. Try to ensure that any injured competitor is adequately
          provided for.
        </p>
        <p>
          Stick to the designated route. No shortcuts will be allowed and if you
          do get lost you should return the way you came to get back onto the
          route.
        </p>
        <p>
          Public nudity will not be condoned. When changing running kit, please
          stay covered to spare blushes.
        </p>
        <p>
          Take your litter with you or put it in a public waste bin. Do not
          pollute rivers, canals, reservoirs.
        </p>
        <p>
          At night and on Sunday morning please try to keep noise to a minimum,
          many citizens will be asleep.
        </p>
        <p>Co-operate with Event Officials.</p>
        <p>The Event Referee’s decision is final.</p>
        <hr></hr>
        <h3>MEDICAL ADVICE</h3>
        <p>
          You are expected to be able to look after yourself. There may be first
          aiders in attendance throughout the event, but we do not have full
          First Aid cover. In addition, competitors are expected to assist
          anyone in need of medical attention and alert a marshal and Race HQ /
          Race Organiser. The Race HQ and Race Organiser phone numbers are
          printed on race numbers.
        </p>
        <p>CELLULITIS</p>
        <p>
          Runners can potentially be at risk of contracting cellulitis. This is
          a potentially serious condition. Cellulitis is usually caused by
          bacteria, most commonly of the streptococci or staphylococci groups,
          which normally live on the skin’s surface. Damage to the skin allows
          bacteria to enter the deeper tissues and establish an infection. The
          initial damage to the skin may be so minor as to go unnoticed. Some
          people are more vulnerable to cellulitis, or more likely to get
          complications, especially those with diabetes, or those whose immune
          system isn’t working well.
        </p>
        <p>
          Symptoms of cellulitis include: Hot, red, swollen and painful area of
          the skin and deeper tissues; Fever; Shivers and chills, caused by
          release of toxins from the bacteria
        </p>
        <p>
          Sometimes a cellulitis infection can be trapped by certain
          ‘compartments’ in the tissues, for example, around a muscle or
          ligament, or in the ‘pulp’ at the tip of a finger or toe. The pressure
          in that compartment increases, cutting off the blood supply (which may
          cause gangrene) and damaging nerves. This needs urgent treatment and
          decompression. A further complication is blood poisoning or
          septicaemia, when bacteria get into the blood stream. This can be
          rapidly fatal and needs very urgent treatment.
        </p>
        <p>
          Advise other members of your family of the symptoms of Cellulitis.
        </p>
        <p>LEPTOSPIROSIS (WEIL’S DISEASE)</p>
        <p>
          If you have contact with canal water, you should be aware of the risk
          of Leptospirosis (Weil’s Disease). It is very rare, but people can
          become infected and awareness of the disease could save your life.
          Leptospires can survive on moist conditions outside for many days,
          even weeks. However, they are readily killed by drying and exposure to
          detergents. The bacteria enter the human body through skin abrasions,
          or through the eyes, nose and mouth.
        </p>
        <p>
          Leptospirosis usually starts with an influenza-like illness. Common
          features are a sudden onset of fever, intense headache, and severe
          pains in calf and back muscles, red eyes and prostration (feeling of
          complete exhaustion). Some cases develop abdominal pains with
          diarrhoea and vomiting or meningitis. Only a few cases develop severe
          Leptospirosis with jaundice and kidney failure. Early diagnosis is
          very important and treatment with antibiotics in the first few days
          may be helpful in limiting the course of the infection. Many sufferers
          recover without specific treatment but 5-10% of patients die.
        </p>
        <p>
          Cover all cuts and abrasions with plasters before the start of the
          race and wash exposed skin after contact with canal/river water.
        </p>
        <p>
          If you are concerned about your health, inform your doctor about your
          recent contact with canal/river water and seek advice.
        </p>
        <p>
          Advise other members of your family of the symptoms of Leptospirosis.
        </p>
        <p>GIANT HOGWEED</p>
        <p>
          Giant hogweed is a common weed which grows by many canals and rivers.
        </p>
        <p>
          The plant produces a phytotoxic sap from stem hairs of the weed and
          can increase the sensitivity of the skin to sunlight, often leading to
          burns and severe blistering which can take months to heal. After
          contact with the plant, the burns can last for several months and the
          skin can remain sensitive to light for many years.
        </p>
        <p>
          Anyone who touches giant hogweed should wash the affected area with
          soap and water, and keep it covered.
        </p>
        <p>
          Giant Hogweed is a large weed with a white or pinkish umbrella-shaped
          flower head. It resembles cow parsley but has reddish-purple stem that
          are up to 5m in height and 5-10cm in diameter with sharp bristles and
          spotted leaf stalks. It can grow about 13ft (4m) high and has leaves
          up to 5ft (1.5m) wide.
        </p>
        <p>
          If you are concerned about your health, inform your doctor about your
          recent contact with Giant Hogweed and seek advice.
        </p>
        <p>
          Advise other members of your family of the symptoms of touching Giant
          Hogweed.
        </p>
        <hr></hr>
        <h3>WAIVER</h3>
        <p>RELEASE OF LIABILITY</p>
        <p>
          In consideration of my participation in the Cheshire 100 Ultra Run
          (the Event), I hereby waive, release, and forever discharge, for
          myself, my heirs, executors, administrators and legal representatives,
          any and all rights and/or claims which I have, may have, or may
          hereafter accrue to me against the Organiser, The Canal and River
          Trust, their officers, directors, employees, consultants and agents
          for any and all damages, injuries (including death), and/or claims
          which may be sustained by me directly or indirectly arising out of my
          participation in the Event (including the application of emergency or
          medical services at the Event), except in the case that the organiser
          has been proved to be negligent in his duty of care towards me.
          Further, I covenant not to sue the Organiser, the Canal and River
          Trust, their officers, directors, employees, consultants and agents
          for any and all damages, injuries (including death), and/or claims
          which may be sustained by me directly or indirectly arising out of my
          participation in the Event, except in the case that the Organiser has
          been proved to be negligent in his duty of care towards me. This
          release and discharge includes, but is not limited to, claims for
          personal injury, death, property damage, economic loss, breach of
          contract, lost wages, contribution indemnity, indemnity, punitive
          damages, or any other legally recognisable claim arising out of my
          participation in the Event (including all legal costs associated with
          such claims).
        </p>
        <p>ASSUMPTION OF THE RISKS</p>
        <p>
          With full knowledge and understanding of the risks associated with my
          participation in the Event, I enter it voluntarily and fully accept
          and assume every such risk for loss, damage, or injury (including
          death).
        </p>
        <p>INDEMNITY</p>
        <p>
          I hereby agree to release, defend, indemnify, and hold harmless the
          Organiser, the Canal and River Trust, their officers, directors,
          employees, consultants and agents from and against any and all claims,
          demands, damages, suits, liabilities or actions arising directly or
          indirectly out of my actions or otherwise from my participation in the
          Event whether made or claimed during or after the Event, including all
          associated costs and expenses and any amounts which the Organiser may
          pay as a settlement or compromise of any such claims or liabilities. I
          will indemnify the Organiser, the Canal and River Trust, their
          directors, officers, employees, agents and consultants against any and
          all claims, lawsuits and causes of action whatsoever or howsoever
          arising and brought against them by any third party as a result of my
          actions during the Event, including without limitation, my violation
          of any laws or regulations or breach of these Terms and Conditions of
          Entry.
        </p>
        <p>SECURITY</p>
        <p>
          I accept all responsibility for the security of my kit and all other
          equipment left in the competitor bag transport.
        </p>
        <p>LITTER</p>
        <p>
          I will not drop litter on any part of the course or in the properties,
          parks and car parks nearby. I will also endeavour to ensure that my
          crew, should I have one, does likewise.
        </p>
        <p>ACCEPTANCE</p>
        <p>
          I have read these terms and conditions, this waiver and the rules and
          regulations that constitute the Conditions of Entry. I fully
          understand the conditions and understand that I will give up
          substantial rights by adding my signature to the “Acceptance of the
          Conditions of Entry” document. I have done so freely and without
          inducement, coercion, or duress. I intend the waiver contained therein
          to be a complete and unconditional release of all liability to the
          greatest extent allowed by law. I agree that, if any portion of this
          waiver is held to be invalid, the balance of the waiver shall continue
          in full force and effect.
        </p>
        <p>COMPLIANCE WITH TERMS AND CONDITIONS OF ENTRY</p>
        <p>
          I understand that failure to comply with the terms and conditions
          listed above could result in disqualification from the race.
        </p>
        <p>
          I agree to comply with all the Event’s terms and conditions of entry.
          I acknowledge and understand that the Organiser of the Event will use
          reasonable efforts to ensure that all other participants abide by the
          terms and conditions of entry, but cannot guarantee that those
          participants will abide by them. My signature on the provided document
          indicates that I have read, understood and accept all terms and
          conditions, and that I agree to abide by the rules and regulations and
          accept the waiver.
        </p>
        <hr></hr>
        <h3>GENERAL</h3>
        <p>
          I will pay my entry fee to cover my individual entry into the Cheshire
          100 Ultra Run. If unable to participate, I understand that:
          <br></br>- If the Organiser is notified of a cancellation before the
          31st of May 2025, I will receive a 100% refund
          <br></br>- If the Organiser is notified of a cancellation between 1st
          and 30th June 2025. I will receive a 75% refund.
          <br></br>- If the Organiser is notified of a cancellation between 1st
          and 31st July 2025 I will receive a 50% refund.
          <br></br>- If the Organiser is notified of a cancellation after the
          31st July 2025 then no refunds for that entry will be processed.
        </p>
        <p>
          In order to cancel a place, please email neweraboxing11@gmail.com as
          soon as the decision is made.
        </p>
        <p>
          If more than 180 days have passed since the original card transaction,
          we will make any refund due via bank transfer to the details provided;
          in this case make sure to include bank account number and sort code
          details.
        </p>
        <p>
          If the organiser cancels the event due to unforseen circumstances a
          full refund will be offered or a transfer to the rescheduled event.
        </p>
        <hr></hr>
      </Container>
    </>
  );
}

export { TermsCond };
