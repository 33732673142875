// import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";

function HeroImage() {
  return (
    <Container className="hero-img-container">
      <div className="padding-spacing"></div>
      <div class="hero-image home-hero-image">
        <div className="main-container">
          <div className="content-container">
            <div className="hero-sub-images">
              <div className="hero-text">
                <h1>THE CHESHIRE 100 ULTRA RUN</h1>
                <h4>20th September 2025</h4>
                <Button
                  href="/how-to-enter"
                  variant="primary"
                  className="button"
                >
                  <h4 style={{ margin: "0" }}>HOW TO ENTER</h4>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export { HeroImage };
